const init = (navSelector = null) => {
  const pmbElem = document.querySelector(navSelector);
  const { metrics } = window;

  if (pmbElem && metrics) {
    [].forEach.call(pmbElem.querySelectorAll('a, button'), (element) => {
      element.addEventListener('click', function handlePmbClick() {
        const linkText = this.dataset.analyticsId || this.textContent.trim();
        const linkName = `promo-message-banner:${linkText}`;
        metrics.link(
          {
            linkName,
          },
          this
        );
      });
    });
  }
};

export default init;
