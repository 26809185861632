/* eslint-disable no-use-before-define */
/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */

function pmbSlider(test) {
  const d = document;
  const pmb = d.querySelector('#promo-message-banner');
  const itemClassName = 'pmb_slide';
  let items = d.getElementsByClassName(itemClassName);
  let itemLinks = d.querySelectorAll('.pmb_link');

  // if no pmb or only one message, abort.
  if (!pmb || items.length === 0) return;

  function checkForMobile() {
    return window.matchMedia('(max-width: 767px)').matches;
  }

  // Variables to target our base class, get slider items,
  // count how many slider items there are,
  // and set motion to true which disables interactivity.
  const next = d.querySelector('.pmb_nextButton');
  const prev = d.querySelector('.pmb_prevButton');
  const pauseBtn = d.querySelector('.pause-button');
  let totalItems = items.length;
  let twoMessages = false;
  let slideIndex = 0;
  let moving = true;
  let pmbInFocus = false;
  let isMobile = checkForMobile();
  let autoSlide;
  let hoverTimer;
  let autoSlideActive = true;

  function setBannerHeight() {
    // this preserves banner height as messages animate
    // and receive absolute positioning
    pmb.style.height = `${pmb.offsetHeight}px`;
  }

  function getSlideTotal() {
    if (totalItems === 2) {
      twoMessages = true;
      // add more slides so slider can wrap.
      const parentEl = d.querySelector('.pmb_viewport');
      for (let i = 0; i < 2; i++) {
        const clone = items[i].cloneNode(true);
        clone.setAttribute('aria-hidden', 'true');
        clone.querySelector('.pmb_link').setAttribute('tabindex', '-1');
        clone.querySelector('.pmb_link').setAttribute('data-slide-index', `${i + 2}`);
        parentEl.appendChild(clone);
      }
      // reset item list and total
      items = d.getElementsByClassName(itemClassName);
      itemLinks = d.querySelectorAll('.pmb_link');
      totalItems = items.length;
    }
  }

  function slidesNotReady() {
    for (let i = 0; i < items.length; i++) {
      items[i].classList.remove('slide-ready');
    }
  }

  function slidesReady() {
    for (let i = 0; i < items.length; i++) {
      items[i].classList.add('slide-ready');
    }
  }

  function setInitialClasses() {
    // Target the last, initial, and next items and give them the relevant class.
    // This assumes there are three or more items.

    slidesReady();
    items[totalItems - 1].classList.add('prev');
    items[0].classList.add('active');
    items[1].classList.add('next');
  }

  // Disable interaction by setting 'moving' to true
  // for the same duration as our transition
  function disableInteraction() {
    moving = true;

    setTimeout(() => {
      moving = false;
    }, 300);
  }

  function moveSliderTo(slide) {
    // Check if slider is moving, if not, allow interaction
    if (!moving) {
      // temporarily disable interactivity
      disableInteraction();

      // Preemptively set variables for the current next and previous slide,
      // as well as the potential next or previous slide.
      let newPrevious = slide - 1;
      let newNext = slide + 1;

      // Check if current slide is at the beginning or end and sets slide numbers
      if (slide === 0) {
        newPrevious = totalItems - 1;
      } else if (slide === totalItems - 1) {
        newPrevious = slide - 1;
        newNext = 0;
      }

      // Reset to default classes.
      for (let i = 0; i < items.length; i++) {
        items[i].className = `${itemClassName} slide-ready`;
        if (!pmbInFocus) items[i].classList.add('animate');
      }
      // Add the new classes.
      items[newPrevious].classList.add('prev');
      items[newNext].classList.add('next');
      items[slide].classList.add('active');
    }
  }

  // Next navigation handler
  function moveNext() {
    // Check if moving
    if (!moving) {
      // If it's the last slide, reset to 0, else +1
      if (slideIndex === totalItems - 1) {
        slideIndex = 0;
      } else {
        slideIndex += 1;
      }

      // Move slider to updated slideIndex
      moveSliderTo(slideIndex);
    }
  }
  // Previous navigation handler
  function movePrev() {
    // Check if moving
    if (!moving) {
      // If it's the first slideIndex, set as the last slideIndex, else -1
      if (slideIndex === 0) {
        slideIndex = totalItems - 1;
      } else {
        slideIndex -= 1;
      }
      // Move slider to updated slideIndex
      moveSliderTo(slideIndex);
    }
  }
  function clearHoverTimer() {
    clearTimeout(hoverTimer);
  }
  function startAutoSlide() {
    autoSlide = setInterval(moveNext, 6000);
  }
  function stopAutoSlide(e) {
    const eventType = e.type;
    // set delay before hover kills auto slide
    if (e.type === 'mouseover') {
      hoverTimer = setTimeout(() => {
        clearInterval(autoSlide);
        removeEvents(e.type);
      }, 200);
    } else {
      clearInterval(autoSlide);
      removeEvents(eventType);
    }
  }
  function removeEvents(type) {
    // remove listeners for auto slide.

    next.removeEventListener(type, stopAutoSlide);
    prev.removeEventListener(type, stopAutoSlide);

    for (let i = 0; i < itemLinks.length; i++) {
      itemLinks[i].removeEventListener(type, stopAutoSlide);
    }
    autoSlideActive = false;
  }

  // Set click events to navigation buttons
  function moveNextByClick(e) {
    if (autoSlideActive) stopAutoSlide(e);
    moveNext();
  }
  function movePrevByClick(e) {
    if (autoSlideActive) stopAutoSlide(e);
    movePrev();
  }

  function setTouchEvents() {
    let swipeStart;
    // const viewport = d.querySelector('.pmb_viewport');
    pmb.addEventListener('touchstart', (e) => {
      stopAutoSlide(e);
      swipeStart = e.changedTouches[0].screenX;
    }, { passive: true });

    pmb.addEventListener('touchend', (e) => {
      if (!swipeStart) return;
      const swipeEnd = e.changedTouches[0].screenX;
      if (swipeStart === swipeEnd) return;
      if (swipeStart > swipeEnd) {
        e.preventDefault();
        moveNextByClick();
      } else {
        e.preventDefault();
        movePrevByClick();
      }
      // Need to reset value, otherwise you get unexpected behavior
      swipeStart = null;
    }, { passive: true });
    // Need to ignore touchstart events on pause button
    pauseBtn?.addEventListener('touchstart', (e) => {
      e.stopPropagation();
    }, { passive: true });
  }

  function handleSlideFocus(e) {
    pmbInFocus = true;
    for (let i = 0; i < itemLinks.length; i++) {
      items[i].classList.remove('animate');
    }

    stopAutoSlide(e);
    const focusIndex = parseInt(e.target.dataset.slideIndex, 10);
    // handle tabbing into PMB when slide is on cloned elements.
    if (twoMessages && slideIndex > 1) {
      if (focusIndex === 0) {
        slideIndex = 3;
        moveNext();
      } else {
        slideIndex = 0;
        moveNext();
      }
    } else {
      if (focusIndex > slideIndex) {
        if (focusIndex === 2 && slideIndex === 0) {
          movePrev();
        } else {
          moveNext();
        }
      }
      if (focusIndex < slideIndex) {
        if (focusIndex === 0 && slideIndex === 2) {
          moveNext();
        } else {
          movePrev();
        }
      }
    }
  }

  function handleSlideBlur() {
    pmbInFocus = false;
  }
  function togglePlay(e) {
    const div = e.currentTarget;
    // Toggle classes
    div.classList.toggle('play');
    div.classList.toggle('pause');
    const isPaused = div.classList.contains('play');
    // Toggle aria messages
    div.ariaLabel = isPaused ? div.dataset.playMsg : div.dataset.pauseMsg;
    // Pause/resume message play
    if (isPaused) {
      stopAutoSlide(e);
    } else {
      startAutoSlide();
    }
  }
  function setControlsEventListeners() {
    // add listeners
    if (next && prev && !isMobile) {
      next.addEventListener('click', moveNextByClick);
      prev.addEventListener('click', movePrevByClick);
      next.addEventListener('mouseover', stopAutoSlide);
      prev.addEventListener('mouseover', stopAutoSlide);
      // keep auto slide active if hover is very short
      next.addEventListener('mouseout', clearHoverTimer);
      prev.addEventListener('mouseout', clearHoverTimer);
    }
    if (pauseBtn) {
      pauseBtn.addEventListener('click', togglePlay);
    }

    for (let i = 0; i < items.length; i++) {
      itemLinks[i].addEventListener('focus', handleSlideFocus);
      itemLinks[i].addEventListener('blur', handleSlideBlur);
      itemLinks[i].addEventListener('mouseover', stopAutoSlide);
      itemLinks[i].addEventListener('mouseout', clearHoverTimer);
    }
    setTouchEvents();
  }

  // check window for mobile
  function windowCheck() {
    observer.disconnect();
    moving = true;
    isMobile = checkForMobile();

    // remove slide ready class.
    slidesNotReady();
    // reset banner height when switching breakpoints.
    pmb.style.height = 'auto';
    setBannerHeight();
    // add slide ready class.
    slidesReady();
    // add listeners if on desktop.
    if (!isMobile) {
      setControlsEventListeners();
    }
    moving = false;
    initObserver();
  }

  // check for text spacing changes
  const observer = new MutationObserver((mutations) => {
    mutations.forEach(() => {
      windowCheck();
    });
  });

  const target = document.getElementById('promo-message-banner');

  function initObserver() {
    // disable oberver if this is a unit test
    if (test) return;
    observer.observe(target, { attributes: true, attributeFilter: ['style'] });
  }

  // Initialize slider
  function initSlider() {
    window.addEventListener('resize', windowCheck);
    initObserver();
    setBannerHeight();
    getSlideTotal();
    setInitialClasses();
    setControlsEventListeners();

    // disable auto slide if reduced motion is turned on
    if (window.matchMedia('(prefers-reduced-motion)').matches) {
      autoSlideActive = false;
    } else {
      startAutoSlide();
    }

    // Set moving to false now that the slider is ready
    moving = false;
  }

  // make it go
  initSlider();
}
export default pmbSlider;
