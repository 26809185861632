/* Promo Message Banner */

import pmbSlider from '../components/pmb-slider';
import swapMessages from '../components/targeted-message';
import pmbAnalyticsInit from '../util/pmb-metrics';

export async function app(win) {
  await swapMessages();
  pmbSlider();
  pmbAnalyticsInit('[data-js="pmb"]');

  return win;
}

export function main(win = window) {
  if (win.document.readyState === 'loading') {
    // Loading hasn't finished yet
    win.document.addEventListener('DOMContentLoaded', app.bind(null, win));
  } else {
    // `DOMContentLoaded` has already fired
    app(win);
  }
}
