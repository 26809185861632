import { getUser } from '@rei/session';

const getPmbElem = () => document.querySelector('[data-js="pmb"]');
const getAltMessagesElm = () => document.querySelector('.pmb_alternate-messages');
const getDefaultMessagesElm = () => document.querySelector('.pmb_default-messages');

/**
 * @param {string|null|undefined} tagName
 * @returns {boolean} true if tagName is for an anchor element
 */
const isSingleMessage = (tagName) => tagName === 'A';

/**
 * If the element param is not a single banner then the left-right arrow buttons
 * are made visible
 * @param {HTMLDivElement|HTMLAnchorElement|{}} element
 */
const showArrowButtons = (element) => {
  const { tagName } = element || {};
  const pmbElem = getPmbElem();
  if (!isSingleMessage(tagName) && pmbElem) {
    const previousBtn = pmbElem.querySelector(
      '[data-js="previous-message-button"]'
    );
    const nextBtn = pmbElem.querySelector('[data-js="next-message-button"]');
    if (previousBtn) {
      previousBtn.classList.remove('pmb_hidden');
    }
    if (nextBtn) {
      nextBtn.classList.remove('pmb_hidden');
    }
    // Show pause button as well
    pmbElem.querySelector('[data-js="pause-button"]')?.classList?.remove('pmb_hidden');
  }
};
/**
 * @param {HTMLDivElement|HTMLAnchorElement|null} element
 */
const showMessages = (element) => {
  if (element) {
    element.classList.remove('pmb_hidden');
    element.classList.add('active');
  }
};

/**
 * @param {HTMLDivElement|HTMLAnchorElement|null} element
 */
const removeMessages = (element) => {
  if (element) {
    element.remove();
  }
};

const getMemberInfo = async () => {
  const user = await getUser();
  return !!user?.isLoggedIn && !!user?.membership;
};

/**
 *
 * @param {HTMLDivElement|HTMLAnchorElement|null} bannerElemToShow
 * @param {HTMLDivElement|HTMLAnchorElement|null} bannerElemToHide
 */
const displayTargetedBanners = (bannerElemToShow, bannerElemToHide = null) => {
  showArrowButtons(bannerElemToShow);
  showMessages(bannerElemToShow);
  removeMessages(bannerElemToHide);
};

const swapMessages = async () => {
  const altMessages = getAltMessagesElm();
  const defaultMessages = getDefaultMessagesElm();
  if (!window.pmbHasMemberMessages) {
    displayTargetedBanners(defaultMessages);
    return;
  }
  const isMember = await getMemberInfo();
  if (isMember) {
    displayTargetedBanners(altMessages, defaultMessages);
  } else {
    displayTargetedBanners(defaultMessages, altMessages);
  }
};

export default swapMessages;
