import '../../../style/content/promo-message-banner/promo-message-banner.scss';
/**
 * If any JS is needed for pmb, add the main entry point to this file.
 */
import { main } from './main/main';

/**
 * This is the main js entry point.
 *
 * @param win Window object. Pass in to allow for window stubbing.
 * @returns {Window}
 */

main(window);
